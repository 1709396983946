"use client"

import { createContext, useContext } from "react"
import { useLocalStorage } from "@uidotdev/usehooks"
import { GoogleOAuthProvider } from "@react-oauth/google"

const clientId =
  "234260149627-p3h49q09ue1r39q168ooum04i6f5o607.apps.googleusercontent.com"

const defaultState = {
  user: null
}

export const AuthContext = createContext(defaultState)

// This component is responsible for storing the user's authentication data in local storage
const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", "null")

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>
    </AuthContext.Provider>
  )
}

// This hook is responsible for retrieving the user's authentication data from local storage
// so that it can be passed to the router context, it also exposes a function to set the user's data
export const useAuth = (router) => {
  const { user, setUser } = useContext(AuthContext)

  const parsedUser = JSON.parse(user)

  const parseAndSetUser = (user) => {
    setUser(JSON.stringify(user))
    router?.invalidate()
  }

  return {
    user: parsedUser,
    setUser: parseAndSetUser
  }
}

export default AuthProvider
