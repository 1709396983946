import { queryOptions } from "@tanstack/react-query"
import api from "./utils/api"

const fetchSeniorities = async () => {
  const seniorities = api.get("/api/seniorities")

  const responses = await Promise.all([seniorities])
  return {
    seniorities: responses[0].data.map((seniority) => ({
      value: seniority.seniority,
      label: seniority.seniority
    }))
  }
}

export const senioritiesQueryOptions = queryOptions({
  queryKey: ["app-data"],
  queryFn: fetchSeniorities
})

export const doAISearch = (query) =>
  api.post("/api/vector_search", { query }).then((res) => res.data)
