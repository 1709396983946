const SearchResultCardLoader = () => {
  return (
    <div className="result-item">
      <div
        style={{
          width: "10rem",
          height: "18px",
          margin: "5px 0",
          borderRadius: "4px"
        }}
        className="pulsate"
      />
      <div
        style={{
          width: "9rem",
          height: "18px",
          margin: "5px 0",
          borderRadius: "4px"
        }}
        className="pulsate"
      />
      <div
        style={{
          width: "9rem",
          height: "18px",
          margin: "5px 0",
          borderRadius: "4px"
        }}
        className="pulsate"
      />
      <div
        style={{
          width: "10rem",
          height: "18px",
          margin: "5px 0",
          borderRadius: "4px"
        }}
        className="pulsate"
      />
      <div
        style={{
          width: "11rem",
          height: "18px",
          margin: "5px 0",
          borderRadius: "4px"
        }}
        className="pulsate"
      />
      <div
        style={{
          width: "9rem",
          height: "18px",
          margin: "5px 0",
          borderRadius: "4px"
        }}
        className="pulsate"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px"
        }}
      >
        <div
          style={{
            width: "15rem",
            height: "18px",
            margin: "5px 0",
            borderRadius: "4px"
          }}
          className="pulsate"
        />
        <div
          style={{
            width: "91px",
            height: "34px",
            borderRadius: "4px"
          }}
          className="pulsate"
        />
      </div>
    </div>
  )
}

export default SearchResultCardLoader
