import { useRouteContext } from "@tanstack/react-router"
import { useState } from "react"
import SearchResultCard from "../components/SearchResultCard"
import { useQuery } from "@tanstack/react-query"
import SearchResultCardLoader from "../components/SearchResultCardLoader"
import { doAISearch } from "../api"

const AISearch = () => {
  const [query, setQuery] = useState("")
  const {
    auth: { user }
  } = useRouteContext({ from: "/ai-search" })
  const { data, isLoading } = useQuery({
    queryKey: ["search-result", query],
    queryFn: () => doAISearch(query),
    enabled: !!query && query.length > 0,
    retry: false
  })

  if (!user) {
    return null
  }

  const handleSearch = async (event) => {
    event.preventDefault()
    setQuery(event.target.searchString.value)
  }

  return (
    <div className="App">
      <h1>AI Search</h1>

      <div className="warning-message">
        <span className="warning-symbol">⚠️</span>
        Please note: This feature is currently in development and may not reflect the most current data. We welcome your feedback as we continue to enhance it!
      </div>

      <form onSubmit={handleSearch}>
        <div className="search-bar">
          <input
            type="text"
            name="searchString"
            placeholder="Search..."
            onChange={(e) => !e.target.value && setQuery("")}
          />
        </div>

        {isLoading && (
          <>
            <SearchResultCardLoader />
            <SearchResultCardLoader />
            <SearchResultCardLoader />
          </>
        )}

        {data?.map((result, index) => (
          <SearchResultCard key={index} result={result} selectedSkills={[]} />
        ))}
      </form>
    </div>
  )
}

export default AISearch
