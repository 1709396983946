import React, { useState } from "react";

const FAQItem = ({ question, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button
        className="faq-question"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <h3>{question}</h3>
        <span className={`chevron ${isOpen ? 'open' : ''}`}>▼</span>
      </button>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        {children}
      </div>
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="App">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="construction-text">
        In this version of the Eidra Competence Portal, only Curamando CVs are included. Only employees with employment status 'Employed' and for which the CV data could be extracted from their Google Drive folder are present in the search results. The availability data for employees is only available up until week 52 of 2024.
      </div>

      <div className="faq-container">
        <FAQItem question="What is the Eidra Competence Portal?">
          <p>The Eidra Competence Portal is a tool for searching and finding competencies within our organization.</p>
        </FAQItem>

        <FAQItem question="Why can't I find myself in the search results?">
          <p>If you cannot find yourself in the search results, in a setting where you should be, it is likely because there was an error when processing your CV. Please make sure your CV follows the standard formatting for your specific company. A page with individual feedback on CVs will come soon, but until then, here are some things to check:</p>
          <ul>
            <li>Make sure the email stated in your CV is correct</li>
            <li>Make sure the tables in your CV (skills, industries and experiences) are correctly filled out and formatted</li>
            <li>Make sure you use the correct formatting for your CV - see the CV template for your company. You can find the curamando CV template <a href="https://docs.google.com/presentation/d/1HRLfbbBpUuAB7heEVtWUYey5ww3INWUm/edit#slide=id.p66" target="_blank" rel="noopener noreferrer">here</a></li>
          </ul>
        </FAQItem>

        <FAQItem question="Why is my CV data not up to date in the search results?">
          <p>If you have several CVs available in Google Drive, only the CV file that was last modified is taken into account, where the file name does not contain the words 'Old' or 'Eidra'. We are working on also extracting experience banks. The CV data is updated once every day, so if you have recently updated your CV, it may take a while before it is updated in the search results.</p>
        </FAQItem>

        <FAQItem question="How do I search for specific skills?">
          <p>You can use the skills filter in the search page to select one or more skills. You can also set a minimum skill level to find people with specific expertise levels.</p>
        </FAQItem>

        <FAQItem question="How does the availability filter work?">
          <p>The availability filter is used to filter on the availability of the employees for a selected time period. "Availability Week Range" is used to select the week numbers between which availability is calculated. "Availavility Rate" is used to select the minimum rate of availability that employees should have to be shown in the search results. Based on the week range selected, the availability is calculated as the percentage of a full time consultant (FTC), i.e., 40 hours, that employees are available during that selected period on average. By selecting a rate of availability, such as '+30%', only employees that are available for at least 30% of the selected time period are shown. Per default, if no filter values are selected, all employees are shown.</p>
        </FAQItem>
      </div>
    </div>
  );
};

export default FAQ; 