import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { Link } from "@tanstack/react-router"

const SearchResultCard = ({ result, selectedSkills, selectedIndustries }) => {
  const getSeniorityLabel = (level) => {
    return `Level ${level}`;
  };

  const renderSkills = (skills) => {
    // Sort skills by level in descending order
    const sortedSkills = [...skills].sort((a, b) => b.level - a.level);

    return sortedSkills.map((skill, index) => {
      const isHighlighted = selectedSkills?.some(s => s.value === skill.name);
      return (
        <div key={index} className="skill-container">
          <span className={`skill-name ${isHighlighted ? 'highlighted' : ''}`}>
            {skill.name}
          </span>
          <span className="skill-dots">
            {[...Array(10)].map((_, i) => (
              <span
                key={i}
                className={`dot ${i < skill.level ? "filled" : "empty"}`}
              />
            ))}
          </span>
        </div>
      );
    });
  };

  const renderAvailabilityDots = (availability) => {
    if (!availability || !availability.length) return null;

    return (
      <div className="availability-container">
        {availability.map((weekData, index) => {
          let colorClass = '';
          if (weekData.available_hours < 10) {
            colorClass = 'red-dot';
          } else if (weekData.available_hours >= 10 && weekData.available_hours <= 30) {
            colorClass = 'yellow-dot';
          } else {
            colorClass = 'green-dot';
          }
          return (
            <div key={index} className="availability-dot-container">
              <span className={`availability-dot ${colorClass}`}>
                W{weekData.week_number}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderIndustries = (industries) => {
    if (!industries) return null;
    return industries.map((industry, index) => {
      const isHighlighted = selectedIndustries?.some(i => i.value === industry);
      return (
        <span
          key={index}
          className={`industry-tag ${isHighlighted ? 'highlighted' : ''}`}
        >
          {industry}
        </span>
      );
    });
  };

  const renderExperiences = (experiences) => {
    if (!experiences) return null;
    // Take only the first 4 experiences
    return experiences.slice(0, 4).map((experience, index) => (
      <div key={index} className="result-experience-item">
        {experience.header}
      </div>
    ));
  };

  return (
    <div className="result-item">
      <div className="result-info">
        <div className="consultant-header">
          <h3 className="consultant-name">{result.name}</h3>
          {result.rating && (
            <span className="rating">
              {result.rating} <span className="star">★</span>
            </span>
          )}
        </div>
        <div className="company-info">
          <span>{result.company}</span>
          {result.department && <span> • {result.department}</span>}
          <span> • {result.track}</span>
        </div>
        <div className="seniority-info">
          {getSeniorityLabel(result.seniority)}
        </div>

        <div className="industries-section">
          <strong>Industries</strong>
          <div className="industries-list">
            {renderIndustries(result.industries)}
          </div>
        </div>
      </div>

      <div className="result-skills">
        <p>
          <strong>Skills</strong>
        </p>
        {renderSkills(result.skills)}
      </div>

      <div className="result-experiences">
        <p><strong>Key Experiences</strong></p>
        {renderExperiences(result.experiences)}
      </div>

      <div className="availability-section">
        <strong>Availability Upcoming Weeks</strong>
        {renderAvailabilityDots(result.availability_per_week)}
      </div>

      <div className="last-cv-update">
        <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
        Last CV Update: {formatDate(result.cv_last_modified)}
      </div>

      <div className="result-link">
        <Link to="/user-profile/$employeeId" params={{ employeeId: result.employee_id }}>
          View Profile
        </Link>
      </div>
    </div>
  );
};


export default SearchResultCard

function formatDate(dateString) {
  const date = new Date(dateString)
  return new Intl.DateTimeFormat("en-US", { month: "long", year: "numeric" }).format(date)
}
