import { jwtDecode } from "jwt-decode";

export const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
        throw new Error("No refresh token available");
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/refresh`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${refreshToken}`,
                "Content-Type": "application/json"
            }
        });

        if (!response.ok) {
            throw new Error("Failed to refresh token");
        }

        const data = await response.json();
        localStorage.setItem("jwtToken", data.access_token);
        return data.access_token;
    } catch (error) {
        console.error("Error refreshing token:", error);
        throw error;
    }
};

export const getValidToken = async () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
        throw new Error("No token available");
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
        // Token has expired, attempt to refresh
        return await refreshToken();
    }

    return token;
};
