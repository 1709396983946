import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../App.css";
import { useSuspenseQuery } from "@tanstack/react-query";
import { senioritiesQueryOptions } from "../api";
import SearchResultCard from "../components/SearchResultCard";
import useDynamicFilters from "../hooks/DynamicFilters";
import { getWeek } from "date-fns";
import api from "../utils/api"
import logo from "../images/logo.svg";

const FilterGroup = ({ label, children }) => (
  <div className="filter-group">
    <label>{label}</label>
    {children}
  </div>
);

const NoResults = () => (
  <div className="no-results">
    <div className="no-results-icon">🔍</div>
    <h3>No Results Found</h3>
    <p>Try adjusting your search criteria or filters to find more consultants.</p>
  </div>
);

const App = () => {
  const {
    data: { seniorities }
  } = useSuspenseQuery(senioritiesQueryOptions);

  const getCurrentWeek = () => getWeek(new Date());

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedTools, setselectedTools] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedSeniority, setSelectedSeniority] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState([]);
  const [selectedSkillTrack, setSelectedSkillTrack] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [results, setResults] = useState([]);
  const [sortMethod, setSortMethod] = useState("name");
  const [selectedSkillLevel, setSelectedSkillLevel] = useState(null);
  const [weekRange, setWeekRange] = useState(null);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);

  // Load the entire dataset once
  const [filterDataset, setDataset] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/api/filter-dataset");
        setDataset(response.data);
      } catch (error) {
        console.error("Error fetching dataset:", error);
      }
    };
    fetchData();
  }, []);

  // Fetch available weeks from the backend
  useEffect(() => {
    const currentWeek = getCurrentWeek();
    const maxWeek = currentWeek + 7
    setWeekRange({
      min: currentWeek,
      max: maxWeek > 52 ? maxWeek - 52 : maxWeek
    });
  }, []);


  // Use the custom hook for dynamic filtering
  const { availableTracks, availableTools, availableSkills, availableIndustries, availableLocations, availableSkillTracks, availableCompanies } = useDynamicFilters(
    filterDataset,
    selectedCompany,
    selectedTrack,
    selectedSkillTrack,
    selectedLocation
  );
  const handleWeekRangeChange = (e) => {
    const { name, value } = e.target;
    setWeekRange((prevRange) => {
      const range = prevRange || { min: '', max: '' };
      const newRange = {
        ...range,
        [name]: value,
      };

      if (!newRange.min && !newRange.max) {
        return null;
      }
      return newRange;
    });
  };

  const handleAvailabilityGradeChange = (selectedOption) => {
    setSelectedAvailability(selectedOption);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/api/search", {
        skills: selectedSkills.map((skill) => skill.value),
        industries: selectedIndustries.map((industry) => industry.value),
        tools: selectedTools.map((tool) => tool.value),
        seniority: selectedSeniority.map(seniority => seniority.value),
        location: selectedLocation.map(location => location.value),
        track: selectedTrack.map(track => track.value),
        skill_track: selectedSkillTrack.map(skillTrack => skillTrack.value),
        company: selectedCompany.map(company => company.value),
        skill_level: selectedSkillLevel ? selectedSkillLevel.value : "",
        search_string: searchString,
        week_range: weekRange,
        availability_grade: selectedAvailability ? selectedAvailability.value : null,
      });
      setResults(response.data);
      setHasSearched(true);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const sortResults = (results, method) => {
    return [...results].sort((a, b) => {
      switch (method) {
        case "track":
          return a.track.localeCompare(b.track)
        case "skill_track":
          return a.skill_track.localeCompare(b.skill_track)
        case "name":
          return a.name.localeCompare(b.name)
        case "seniorityHigh":
          return b.seniority.localeCompare(a.seniority)
        case "seniorityLow":
          return a.seniority.localeCompare(b.seniority)
        case "modifiedHigh":
          return new Date(b.cv_last_modified).getTime() - new Date(a.cv_last_modified).getTime();
        case "modifiedLow":
          return new Date(a.cv_last_modified).getTime() - new Date(b.cv_last_modified).getTime();
        case "skill":
          if (selectedSkills.length > 0) {
            const skillName = selectedSkills[0].value
            const skillA = a.skills.find((s) => s.name === skillName)
            const skillB = b.skills.find((s) => s.name === skillName)
            return (skillB ? skillB.level : 0) - (skillA ? skillA.level : 0)
          }
          return 0
        default:
          return 0
      }
    })
  }

  return (
    <div className="App">
      <h1 aria-label="Eidra Competence Portal">
        <img src={logo} alt="Eidra" className="eidra-logo" />
      </h1>
      <form onSubmit={handleSearch}>
        <div className="filters">
          <div className="regular-filters">
            <div className="search-bar">
              <input
                type="text"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder="Search for consultants, experiences, skills, ..."
              />
            </div>

            <Select
              isMulti
              options={availableSkills}
              value={selectedSkills}
              onChange={setSelectedSkills}
              placeholder="All skills"
            />

            <Select
              options={[
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
                { value: 6, label: "6" },
                { value: 7, label: "7" },
                { value: 8, label: "8" },
                { value: 9, label: "9" },
                { value: 10, label: "10" }
              ]}
              value={selectedSkillLevel}
              onChange={setSelectedSkillLevel}
              isClearable
              placeholder="All skill levels"
            />

            <Select
              isMulti
              options={availableTools}
              value={selectedTools}
              onChange={setselectedTools}
              placeholder="All tools"
            />

            <Select
              isMulti
              options={availableIndustries}
              value={selectedIndustries}
              onChange={setSelectedIndustries}
              placeholder="All industries"
            />

            <Select
              isMulti
              options={seniorities}
              value={selectedSeniority}
              onChange={setSelectedSeniority}
              placeholder="All seniorities"
            />

            <Select
              isMulti
              options={availableLocations}
              value={selectedLocation}
              onChange={setSelectedLocation}
              placeholder="All locations"
              className="react-select"
            />

            <Select
              isMulti
              options={availableCompanies}
              value={selectedCompany}
              onChange={setSelectedCompany}
              placeholder="All companies"
            />

            <Select
              isMulti
              options={availableTracks}
              value={selectedTrack}
              onChange={setSelectedTrack}
              placeholder="All tracks"
              className="react-select"
            />

            <Select
              isMulti
              options={availableSkillTracks}
              value={selectedSkillTrack}
              onChange={setSelectedSkillTrack}
              placeholder="All skill tracks"
              className="react-select"
            />
          </div>

          <div className="availability-filter-section">
            <FilterGroup label="Availability">
              <div className="week-range-container">
                <span className="week-range-container">Week</span>
                <input
                  type="number"
                  name="min"
                  value={weekRange?.min || ''}
                  onChange={handleWeekRangeChange}
                  min="1"
                  max="52"
                  placeholder="Start Week"
                  className="week-range-input"
                />
                <span className="week-range-separator">to</span>
                <input
                  type="number"
                  name="max"
                  value={weekRange?.max || ''}
                  onChange={handleWeekRangeChange}
                  min="1"
                  max="52"
                  placeholder="End Week"
                  className="week-range-input"
                />
              </div>
              <div className="availability-buttons-container">
                {[
                  {
                    value: 'low',
                    label: '+30% FTC',
                  },
                  {
                    value: 'medium',
                    label: '+60% FTC',
                  },
                  {
                    value: 'high',
                    label: '100% FTC',
                  }
                ].map((option) => (
                  <button
                    key={option.value}
                    type="button"
                    onClick={() => handleAvailabilityGradeChange(
                      selectedAvailability?.value === option.value ? null : option
                    )}
                    className={`availability-button ${selectedAvailability?.value === option.value ? 'selected' : ''}`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </FilterGroup>
            <button type="submit">Search Consultants</button>
          </div>
        </div>
      </form>

      <div className="results">
        {hasSearched && results.length > 0 && (
          <div className="results-header">
            <h2>Results</h2>
            <div className="sort-options sort-container">
              <label className="sort-label">Sort by </label>
              <select value={sortMethod} onChange={(e) => setSortMethod(e.target.value)}>
                <option value="name">Name</option>
                <option value="track">Track</option>
                <option value="skill_track">Skill Track</option>
                <option value="seniorityHigh">Seniority (High to Low)</option>
                <option value="seniorityLow">Seniority (Low to High)</option>
                <option value="modifiedHigh">Last CV Update (Most Recent First)</option>
                <option value="modifiedLow">Last CV Update (Most Recent Last)</option>
                {selectedSkills.length > 0 && (
                  <option value="skill">Skill Proficiency</option>
                )}
              </select>
            </div>
          </div>
        )}
        {hasSearched && results.length === 0 ? (
          <NoResults />
        ) : (
          sortResults(results, sortMethod).map((result, index) => (
            <SearchResultCard
              key={index}
              result={result}
              selectedSkills={selectedSkills}
              selectedIndustries={selectedIndustries}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default App;
