import React, { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import AuthProvider, { useAuth } from "./components/AuthContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import rootRoute, { childRoutes } from "./routes"

const queryClient = new QueryClient()

// Register your routes
const routeTree = rootRoute.addChildren(childRoutes)

// Create the router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
  context: {
    auth: undefined, // This will be set by the InnerApp component
    queryClient
  }
})

const InnerApp = () => {
  const auth = useAuth()
  return <RouterProvider router={router} context={{ auth, queryClient }} />
}

const rootElement = document.getElementById("root")
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <InnerApp />
        </AuthProvider>
      </QueryClientProvider>
    </StrictMode>
  )
}

reportWebVitals()
