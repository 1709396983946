import React from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../utils/api";
import "../App.css";

const Profile = () => {
    const { data: profile, isLoading, error } = useQuery({
        queryKey: ["profile"],
        queryFn: async () => {
            const response = await api.get("/api/profile");
            return response.data;
        },
    });

    if (isLoading) return <div className="App">Loading...</div>;
    if (error) return <div className="App">Error: {error.message}</div>;
    if (!profile || profile.length === 0) return <div className="App">No profile data found</div>;

    const userData = profile[0];

    const renderAvailabilityDots = (availability) => {
        if (!availability || !availability.length) return null;

        return (
            <div className="availability-container">
                {availability.map((weekData, index) => {
                    let colorClass = '';
                    if (weekData.available_hours < 10) {
                        colorClass = 'red-dot';
                    } else if (weekData.available_hours >= 10 && weekData.available_hours <= 30) {
                        colorClass = 'yellow-dot';
                    } else {
                        colorClass = 'green-dot';
                    }
                    return (
                        <div key={index} className="availability-dot-container">
                            <span className={`availability-dot ${colorClass}`}>
                                W{weekData.week_number}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="App">
            <div className="profile-container">
                <div className="profile-card">
                    <div className="profile-header">
                        <h2>{userData.name}</h2>
                        <p className="profile-company">{userData.company}</p>
                    </div>

                    <div className="profile-section">
                        <h3>Basic Information</h3>
                        <div className="profile-info-grid">
                            <div className="info-item">
                                <strong>Track:</strong> {userData.track}
                            </div>
                            <div className="info-item">
                                <strong>Skill Track:</strong> {userData.skill_track}
                            </div>
                            <div className="info-item">
                                <strong>Seniority:</strong> {userData.seniority}
                            </div>
                            <div className="info-item">
                                <strong>Location:</strong> {userData.location}
                            </div>
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Experiences</h3>
                        <div className="experiences-grid">
                            {userData.experiences.map((experience, index) => (
                                <div key={index} className="experience-item">
                                    <h4 className="experience-header">{experience.header}</h4>
                                    {experience.description && (
                                        <p className="experience-description">{experience.description}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Skills</h3>
                        <div className="skills-grid">
                            {userData.skills.map((skill, index) => (
                                <div key={index} className="skill-item">
                                    <span className="skill-name">{skill.name}</span>
                                    <div className="skill-level">
                                        {[...Array(10)].map((_, i) => (
                                            <span
                                                key={i}
                                                className={`dot ${i < skill.level ? "filled" : "empty"}`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Tools</h3>
                        <div className="tools-grid">
                            {userData.tools.map((tool, index) => (
                                <span key={index} className="tool-tag">
                                    {tool}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Industries</h3>
                        <div className="industries-grid">
                            {userData.industries.map((industry, index) => (
                                <span key={index} className="industry-tag">
                                    {industry}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Availability</h3>
                        {renderAvailabilityDots(userData.availability_per_week)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile; 
