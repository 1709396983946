import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "@tanstack/react-router";
import { useRouteContext } from "@tanstack/react-router";
import logo from "../images/logo.svg";

const LoginPage = () => {
    const navigate = useNavigate();
    const {
        auth: { setUser }
    } = useRouteContext({ from: "/" });

    const onSuccess = (response) => {
        console.log("Login Success");
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/google`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id_token: response.credential })
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    console.error("Error:", data.error);
                } else {
                    localStorage.setItem("jwtToken", data.access_token);
                    localStorage.setItem("refreshToken", data.refresh_token);
                    const { access_token, refresh_token, ...userWithoutTokens } = data;
                    setUser(userWithoutTokens);
                    navigate({ to: "/search" });
                }
            });
    };

    const onFailure = (response) => {
        console.log("Login failed: res:", response);
    };

    return (
        <>
            <div className="navbar">
                {/* Empty navbar for consistent styling */}
            </div>
            <div className="App">
                <div className="login-container">
                    <div className="welcome-text">Welcome to</div>
                    <h1 aria-label="Eidra Competence Portal">
                        <img src={logo} alt="Eidra" className="eidra-logo" />
                    </h1>
                    <p className="login-text">Please log in to continue</p>
                    <div className="login-button">
                        <GoogleLogin
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                        />
                    </div>
                    <p className="construction-text">
                        NOTE: This application is under development in a beta-testing phase.<br />
                        Therefore, is not accessible to all Eidra employees yet.
                    </p>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
