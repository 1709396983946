import { Outlet, useRouteContext, useNavigate } from "@tanstack/react-router"
import NavBar from "../components/NavBar"
import { useEffect } from "react"

// Layout component for the entire application
const Layout = () => {
  const {
    auth: { user }
  } = useRouteContext({ from: "" })
  const navigate = useNavigate()

  useEffect(() => {
    if (!user && window.location.pathname !== "/") {
      navigate({ to: "/" })
    }
  }, [user, navigate])

  return (
    <>
      <NavBar />
      <Outlet />
    </>
  )
}

export default Layout
