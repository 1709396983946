import axios from "axios"
import { refreshToken, getValidToken } from "./auth"

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
})

api.interceptors.request.use(
    async (config) => {
        try {
            const token = await getValidToken()
            config.headers["Authorization"] = `Bearer ${token}`
        } catch (error) {
            console.error("Error getting valid token:", error)
            // Redirect to login page if token refresh fails
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("user");
            window.location.href = '/';
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            try {
                await refreshToken()
                const token = await getValidToken()
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                return api(originalRequest)
            } catch (refreshError) {
                console.error("Error refreshing token:", refreshError)
                // Redirect to login page if token refresh fails
                localStorage.removeItem("jwtToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("user");
                window.location.href = '/';
                return Promise.reject(refreshError)
            }
        }
        return Promise.reject(error)
    }
)

export default api
