/**
 * Custom hook for managing dynamic filtering of consultant data
 * 
 * Filter Hierarchy and Dependencies:
 * 1. Companies (Top-level filter)
 *    - Affects all other filters
 *    - Not affected by any other filters
 *    - Always shows all available companies
 *    - Sorted alphabetically
 * 
 * 2. Tracks & Skill Tracks (Second-level filters)
 *    - Affected by: Companies
 *    - Affects: Tools, Skills, Industries, Locations
 *    - Sorted alphabetically
 * 
 * 3. Tools, Skills, Industries (Frequency-based filters)
 *    - Affected by: Companies, Tracks, Skill Tracks
 *    - Shows top 20 most frequent items
 *    - Sorted by frequency (descending)
 * 
 * 4. Locations (Basic filter)
 *    - Affected by: Companies, Tracks, Skill Tracks
 *    - Sorted alphabetically
 * 
 * @param {Object[]} dataset - The complete dataset of consultants
 * @param {Object[]} selectedCompanies - Currently selected companies
 * @param {Object[]} selectedTracks - Currently selected tracks
 * @param {Object[]} selectedSkillTracks - Currently selected skill tracks
 * @param {Object} selectedLocation - Currently selected location
 * 
 * @returns {Object} An object containing arrays of available options for each filter:
 *    - availableCompanies: All companies, unaffected by other filters
 *    - availableTracks: Tracks available based on selected companies
 *    - availableSkillTracks: Skill tracks available based on selected companies and tracks
 *    - availableTools: Top 20 tools based on frequency and selected filters
 *    - availableSkills: Top 20 skills based on frequency and selected filters
 *    - availableIndustries: Top 20 industries based on frequency and selected filters
 *    - availableLocations: Locations available based on selected filters
 * 
 * Filter Format:
 * All filter options are formatted as { value: string, label: string } for React-Select
 * 
 * Filtering Process:
 * 1. Companies are set independently from the full dataset
 * 2. Dataset is filtered by selected companies
 * 3. Tracks and Skill Tracks are filtered based on remaining dataset
 * 4. Tools, Skills, and Industries are counted and top 20 are selected
 * 5. Locations are filtered based on final dataset
 * 
 */


import { useState, useEffect } from "react";

const useDynamicFilters = (
  dataset,
  selectedCompanies,
  selectedTracks,
  selectedSkillTracks,
  selectedLocation
) => {
  const [availableTracks, setAvailableTracks] = useState([]);
  const [availableTools, setAvailableTools] = useState([]);
  const [availableSkills, setAvailableSkills] = useState([]);
  const [availableIndustries, setAvailableIndustries] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableSkillTracks, setAvailableSkillTracks] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);

  useEffect(() => {
    let filteredDataset = dataset;

    const filteredCompanies = filteredDataset.map(item => item.company).filter(company => company);
    const uniqueCompanies = [...new Set(filteredCompanies)]
      .sort((a, b) => a.localeCompare(b));
    setAvailableCompanies(uniqueCompanies.map(company => ({ value: company, label: company })));

    if (selectedCompanies?.length > 0) {
      filteredDataset = filteredDataset.filter(item =>
        selectedCompanies.some(company => company.value === item.company)
      );
    }

    const filteredTracks = filteredDataset.map(item => item.track).filter(track => track);
    const uniqueTracks = [...new Set(filteredTracks)]
      .sort((a, b) => a.localeCompare(b));
    setAvailableTracks(uniqueTracks.map(track => ({ value: track, label: track })));

    if (selectedTracks?.length > 0) {
      filteredDataset = filteredDataset.filter(item =>
        selectedTracks.some(track => track.value === item.track)
      );
    }

    const filteredSkillTracks = filteredDataset.map(item => item.skill_track).filter(skillTrack => skillTrack);
    const uniqueSkillTracks = [...new Set(filteredSkillTracks)]
      .sort((a, b) => a.localeCompare(b));
    setAvailableSkillTracks(uniqueSkillTracks.map(skillTrack => ({ value: skillTrack, label: skillTrack })));

    if (selectedSkillTracks?.length > 0) {
      filteredDataset = filteredDataset.filter(item =>
        selectedSkillTracks.some(skillTrack => skillTrack.value === item.skill_track)
      );
    }

    const toolCounts = filteredDataset.flatMap(item => item.tools).filter(tool => tool && tool.trim() !== "")
      .reduce((acc, tool) => {
        acc[tool] = (acc[tool] || 0) + 1;
        return acc;
      }, {});
    const sortedTools = Object.entries(toolCounts).sort((a, b) => b[1] - a[1]).slice(0, 20)
      .map(([tool]) => ({ value: tool, label: tool }));
    setAvailableTools(sortedTools);

    const skillCounts = filteredDataset.flatMap(item => item.skills.map(skill => skill.name)).filter(skill => skill)
      .reduce((acc, skill) => {
        acc[skill] = (acc[skill] || 0) + 1;
        return acc;
      }, {});
    const sortedSkills = Object.entries(skillCounts).sort((a, b) => b[1] - a[1]).slice(0, 20)
      .map(([skill]) => ({ value: skill, label: skill }));
    setAvailableSkills(sortedSkills);

    const industryCounts = filteredDataset.flatMap(item => item.industries).filter(industry => industry)
      .reduce((acc, industry) => {
        acc[industry] = (acc[industry] || 0) + 1;
        return acc;
      }, {});
    const sortedIndustries = Object.entries(industryCounts).sort((a, b) => b[1] - a[1]).slice(0, 20)
      .map(([industry]) => ({ value: industry, label: industry }));
    setAvailableIndustries(sortedIndustries);

    const filteredLocations = filteredDataset.map(item => item.location).filter(location => location);
    const uniqueLocations = [...new Set(filteredLocations)].sort((a, b) => a.localeCompare(b));
    setAvailableLocations(uniqueLocations.map(location => ({ value: location, label: location })));

  }, [
    selectedCompanies,
    selectedTracks,
    selectedSkillTracks,
    selectedLocation,
    dataset
  ]);

  return { availableTracks, availableTools, availableSkills, availableIndustries, availableLocations, availableSkillTracks, availableCompanies };
};

export default useDynamicFilters;